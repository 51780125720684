import * as React from 'react'

import {
  AppointmentFormTypes,
  AppointmentUtils,
  BookAppointmentFormLayout,
  Components,
  Containers,
} from '@grandvisionhq/appointments'
import { Button, Delayed } from '@grandvisionhq/common'
import { Text } from '@grandvisionhq/elements'
import {
  ComposedForm,
  Date,
  FormControl,
  FormTypes,
  PrefixSelect,
  Util,
} from '@grandvisionhq/forms'
import { Types } from '@grandvisionhq/graphql'
import { useIntl } from '@grandvisionhq/state'

import { consents, optIns } from '../../configuration'
import { isPhoneNumber, isValidPostalCode } from '../../util/form-validation'

const { ConsentsAndOptIns, OptionalFieldset } = Components
const { SignInBox } = Containers

const BookAppointmentForm: AppointmentFormTypes.FormComponent = ({
  layout,
  loggedIn,
  submitting = false,
  formAlert = null,
  onChange,
  disableSubmit = false,
  beforeSubmitSlot = null,
  ...rest
}) => {
  const { getLabel } = useIntl()

  const options = React.useMemo(
    () =>
      Util.createSimpleSelectOptions(
        ['mr', 'mrs', 'miss', 'ms', 'dr', 'mx'].map((key) =>
          getLabel(`appointments.component.bookAppointmentForm.salutation.${key}`)
        )
      ),
    [getLabel]
  )

  const onChangeHandler = React.useCallback(
    (state: FormTypes.ComposedFormState) => {
      onChange?.(state)
    },
    [onChange]
  )

  return (
    <ComposedForm {...rest} className="book-appointment-form" onChange={onChangeHandler}>
      {!loggedIn && <SignInBox />}
      {layout === BookAppointmentFormLayout.CHILD && (
        <OptionalFieldset
          labels={{ legend: getLabel('appointments.component.bookAppointmentForm.legend.child') }}
          active={layout === BookAppointmentFormLayout.CHILD}
        >
          <ComposedForm.Part name="childDetails">
            <FormControl
              {...FormControl.defaultProps}
              id="childFirstName"
              data-t="child-first-name"
              label={getLabel('appointments.component.bookAppointmentForm.label.child.firstName')}
              validations={[
                Util.validations.minLength(2),
                Util.validations.maxLength(30),
                Util.validations.isTextWithApostrophe,
              ]}
              required
              errorMessage={getLabel(
                'appointments.component.bookAppointmentForm.error.child.firstName'
              )}
            />
            <FormControl
              {...FormControl.defaultProps}
              id="childLastName"
              data-t="child-last-name"
              label={getLabel('appointments.component.bookAppointmentForm.label.child.lastName')}
              validations={[
                Util.validations.minLength(2),
                Util.validations.maxLength(30),
                Util.validations.isTextWithApostrophe,
              ]}
              required
              errorMessage={getLabel(
                'appointments.component.bookAppointmentForm.error.child.lastName'
              )}
            />
            <Date
              {...FormControl.defaultProps}
              tooltipText={getLabel(
                'appointments.component.bookAppointmentForm.tooltipText.child.dateOfBirth'
              )}
              additionalScreenReaderLabel={getLabel(
                'appointments.component.bookAppointmentForm.accessibleLabel.child.dateOfBirth'
              )}
              label={getLabel('appointments.component.bookAppointmentForm.label.child.dateOfBirth')}
              name="childDateOfBirth"
              fieldLabels={{
                childDateOfBirthDay: getLabel(
                  'appointments.component.bookAppointmentForm.label.child.dateOfBirth.day'
                ),
                childDateOfBirthMonth: getLabel(
                  'appointments.component.bookAppointmentForm.label.child.dateOfBirth.month'
                ),
                childDateOfBirthYear: getLabel(
                  'appointments.component.bookAppointmentForm.label.child.dateOfBirth.year'
                ),
              }}
              invalidDateErrorMessage={getLabel(
                'appointments.component.bookAppointmentForm.error.child.dateOfBirth.invalidDate'
              )}
              required={true}
            />
          </ComposedForm.Part>
        </OptionalFieldset>
      )}
      <OptionalFieldset
        labels={{
          legend: getLabel('appointments.component.bookAppointmentForm.legend.personalDetails'),
        }}
        active={true}
      >
        <ComposedForm.Part name="personalDetails">
          <PrefixSelect
            options={options}
            label={getLabel(
              'appointments.component.bookAppointmentForm.label.personalDetails.salutation'
            )}
            errorMessage={getLabel(
              'appointments.component.bookAppointmentForm.error.personalDetails.salutation'
            )}
            placeholder={getLabel(
              'appointments.component.bookAppointmentForm.placeholder.personalDetails.salutation'
            )}
            required={true}
          />
          <FormControl
            {...FormControl.defaultProps}
            id="firstName"
            data-t="personal-details-first-name"
            label={getLabel(
              'appointments.component.bookAppointmentForm.label.personalDetails.firstName'
            )}
            validations={[
              Util.validations.minLength(2),
              Util.validations.maxLength(30),
              Util.validations.isTextWithApostrophe,
            ]}
            required
            errorMessage={getLabel(
              'appointments.component.bookAppointmentForm.error.personalDetails.firstName'
            )}
          />
          <FormControl
            {...FormControl.defaultProps}
            id="lastName"
            data-t="personal-details-last-name"
            label={getLabel(
              'appointments.component.bookAppointmentForm.label.personalDetails.lastName'
            )}
            validations={[
              Util.validations.minLength(2),
              Util.validations.maxLength(30),
              Util.validations.isTextWithApostrophe,
            ]}
            required
            errorMessage={getLabel(
              'appointments.component.bookAppointmentForm.error.personalDetails.lastName'
            )}
          />
          <Date
            {...FormControl.defaultProps}
            tooltipText={getLabel(
              'appointments.component.bookAppointmentForm.tooltipText.personalDetails.dateOfBirth'
            )}
            name="dateOfBirth"
            additionalScreenReaderLabel={getLabel(
              'appointments.component.bookAppointmentForm.accessibleLabel.personalDetails.dateOfBirth'
            )}
            label={getLabel(
              'appointments.component.bookAppointmentForm.label.personalDetails.dateOfBirth'
            )}
            fieldLabels={{
              dateOfBirthDay: getLabel(
                'appointments.component.bookAppointmentForm.label.personalDetails.dateOfBirth.day'
              ),
              dateOfBirthMonth: getLabel(
                'appointments.component.bookAppointmentForm.label.personalDetails.dateOfBirth.month'
              ),
              dateOfBirthYear: getLabel(
                'appointments.component.bookAppointmentForm.label.personalDetails.dateOfBirth.year'
              ),
            }}
            validations={[
              {
                validator: Util.validations.isOlderThan(16),
                errorMessage: getLabel(
                  'appointments.component.bookAppointmentForm.error.personalDetails.dateOfBirth.invalidAge'
                ),
              },
            ]}
            invalidDateErrorMessage={getLabel(
              'appointments.component.bookAppointmentForm.error.personalDetails.dateOfBirth.invalidDate'
            )}
            required={true}
          />
        </ComposedForm.Part>
      </OptionalFieldset>
      <OptionalFieldset
        labels={{
          legend: getLabel('appointments.component.bookAppointmentForm.legend.contactDetails'),
        }}
        active={true}
      >
        <ComposedForm.Part name="contactDetails">
          <FormControl
            {...FormControl.defaultProps}
            id="email"
            type="email"
            data-t="contact-details-email"
            tooltip={getLabel(
              'appointments.component.bookAppointmentForm.tooltipText.contactDetails.email'
            )}
            label={getLabel(
              'appointments.component.bookAppointmentForm.label.contactDetails.email'
            )}
            disabled={loggedIn}
            validations={[Util.validations.isEmailAddress]}
            errorMessage={getLabel(
              'appointments.component.bookAppointmentForm.error.contactDetails.email'
            )}
          />
          <FormControl
            {...FormControl.defaultProps}
            id="mobilePhone"
            type="tel"
            data-t="contact-details-mobile-phone"
            tooltip={getLabel(
              'appointments.component.bookAppointmentForm.tooltipText.contactDetails.phone'
            )}
            label={getLabel(
              'appointments.component.bookAppointmentForm.label.contactDetails.mobilePhone'
            )}
            validations={[isPhoneNumber]}
            errorMessage={getLabel(
              'appointments.component.bookAppointmentForm.error.contactDetails.mobilePhone'
            )}
          />
          <FormControl
            {...FormControl.defaultProps}
            className="book-appointment-form__postalCode"
            tooltip={getLabel(
              'appointments.component.bookAppointmentForm.tooltipText.contactDetails.postalCode'
            )}
            id="postalCode"
            data-t="contact-details-postal-code"
            label={getLabel(
              'appointments.component.bookAppointmentForm.label.contactDetails.postalCode'
            )}
            validations={[isValidPostalCode]}
            errorMessage={getLabel(
              'appointments.component.bookAppointmentForm.error.contactDetails.postalCode'
            )}
          />
          <Text variant="caption" color="text-secondary">
            {getLabel(
              'appointments.component.bookAppointmentForm.placeholder.contactDetails.postalCode'
            )}
          </Text>
        </ComposedForm.Part>
      </OptionalFieldset>
      {!loggedIn && (
        <ComposedForm.Part name="consentsAndOptIns">
          <ConsentsAndOptIns
            className="book-appointment-form__spacing-top"
            errorMessage={getLabel('appointments.component.consentsAndOptIns.error')}
            consents={consents}
            optIns={optIns}
          />
        </ComposedForm.Part>
      )}
      {formAlert}
      {beforeSubmitSlot}
      <Button
        type="submit"
        caption={getLabel('appointments.component.bookAppointmentForm.submit')}
        isLoading={submitting}
        disabled={disableSubmit}
        className="book-appointment-form__spacing-top"
      />

      {submitting && (
        <Delayed delay={2500} scrollIntoView className="book-appointment-form__spacing-top">
          <Text
            color="text-secondary"
            align="center"
            className="book-appointment-form__spacing-top"
          >
            {getLabel('appointments.component.bookAppointmentForm.slowLoadingIndication')}
          </Text>
        </Delayed>
      )}
    </ComposedForm>
  )
}

BookAppointmentForm.fromDataToState = (data, { layout }) => {
  let state: FormTypes.ComposedFormValueState = {}

  const child: Record<string, string | number> = {
    childFirstName: '',
    childLastName: '',
    childDateOfBirth: '',
  }
  const personalDetails: Record<string, string | number> = {
    salutation: data.defaultBillingAddress?.salutation ?? data.personalDetails?.salutation ?? '',
    firstName: data.defaultBillingAddress?.firstName ?? data.personalDetails?.firstName ?? '',
    lastName: data.defaultBillingAddress?.lastName ?? data.personalDetails?.lastName ?? '',
    dateOfBirth: data.personalDetails?.dateOfBirth,
  }
  const contactDetails: Record<string, string | number> = {
    email: data.email,
    mobilePhone: data.personalDetails?.phone ?? '',
    postalCode: data.defaultBillingAddress?.postalCode ?? '',
  }

  switch (layout) {
    case BookAppointmentFormLayout.CHILD:
      state = Util.mergeComposedFormValueState(state, {
        childDetails: Util.fieldsToFormState(child),
      })
    default:
      state = Util.mergeComposedFormValueState(state, {
        personalDetails: Util.fieldsToFormState(personalDetails),
      })
      state = Util.mergeComposedFormValueState(state, {
        contactDetails: Util.fieldsToFormState(contactDetails),
      })
  }

  return state
}

BookAppointmentForm.getFormGroups = ({ layout }: AppointmentFormTypes.GetFormGroups): string[] =>
  layout === BookAppointmentFormLayout.CHILD
    ? ['childDetails', 'personalDetails', 'contactDetails', 'consentsAndOptIns']
    : ['personalDetails', 'contactDetails', 'consentsAndOptIns']

BookAppointmentForm.fromStateToData = (state, { layout, intl }, metadata) => {
  const data = Util.getValuesFromComposedState(state)
  const optInsAreDisplayed = data.consentsAndOptIns && 'marketingEmails' in data.consentsAndOptIns
  const consentsAreDisplayed = data.consentsAndOptIns && 'createAccount' in data.consentsAndOptIns
  const createAccountConsent = consents.find((consent) => consent.value === 'createAccount')
  const optIns = createAccountConsent?.children ?? []

  const result: AppointmentFormTypes.FormStateData = {
    child:
      layout === BookAppointmentFormLayout.CHILD
        ? {
            firstName: data.childDetails?.childFirstName ?? '',
            lastName: data.childDetails?.childLastName ?? '',
            dateOfBirth: Util.toStandardDateFormat(data.childDetails?.childDateOfBirth) ?? '',
          }
        : undefined,
    personalDetails: {
      salutation: data.personalDetails?.salutation ?? '',
      firstName: data.personalDetails?.firstName ?? '',
      lastName: data.personalDetails?.lastName ?? '',
      dateOfBirth: Util.toStandardDateFormat(data.personalDetails?.dateOfBirth),
      email: data.contactDetails?.email,
      phone: data.contactDetails?.mobilePhone ?? '',
      billingAddress: {
        salutation: data.personalDetails?.salutation ?? '',
        firstName: data.personalDetails?.firstName ?? '',
        lastName: data.personalDetails?.lastName ?? '',
        postalCode: data.contactDetails?.postalCode ?? '',
      },
    },
    optins:
      optInsAreDisplayed && !metadata?.loggedIn
        ? [
            {
              id: 'marketingEmails',
              value: data.consentsAndOptIns?.marketingEmails === 'true',
              uiType: Types.UIType.CHECKBOX,
              literalText: intl.getFormattedLabel(
                AppointmentUtils.getLiteralText('marketingEmails', optIns) ?? '',
                { parseHTML: false, parseLink: true }
              ),
            },
          ]
        : null,
    customerComment: null,
    preferredChannelOfCommunication: null,
    consents:
      consentsAreDisplayed && !metadata?.loggedIn
        ? [
            {
              consentName: 'terms-conditions',
              value: data.consentsAndOptIns?.createAccount === 'true',
              uiType: Types.UIType.CHECKBOX,
              literalText: intl.getFormattedLabel(
                AppointmentUtils.getLiteralText('createAccount', consents) ?? '',
                { parseHTML: false, parseLink: true }
              ),
            },
          ]
        : null,
    setPreferredStore: !metadata?.loggedIn ? Boolean(data.consentsAndOptIns.preferredStore) : null,
  }

  return result
}

export default BookAppointmentForm
