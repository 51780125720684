import * as React from 'react'

import { Text } from '@grandvisionhq/elements'
import { useIntl } from '@grandvisionhq/state'

interface Props {
  startTime: Date
}

export const ConfirmationTime: React.FC<Props> = ({ startTime }) => {
  const { dateTime } = useIntl()
  return (
    <>
      <Text>{dateTime.formatLongDate(startTime)}</Text>
      <Text>{dateTime.formatTime(startTime)}</Text>
    </>
  )
}

export const confirmationPage = {
  time: ({ startTime }) => <ConfirmationTime startTime={startTime} />,
}
