import { Util } from '@grandvisionhq/forms'

const { isValidPostalCodeForCountry } = Util.validations

export const isPhoneNumber = (value: string): boolean => REGEX.PHONE_NUMBER.test(value)

export const isValidPostalCode = (value: string): boolean =>
  isValidPostalCodeForCountry('GB', value)

const REGEX = {
  PHONE_NUMBER: /^\d{10,13}$/,
  MOBILE_PHONE_NUMBER: /^08\d{8}$/,
  WHITESPACE: /\s/g,
}
