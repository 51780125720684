import * as React from 'react'

import { confirmationPage } from 'components/confirmationPage'
import { defaultErrorProps } from 'components/error-props'

import { NextPage, withErrorPage } from '@grandvisionhq/next'
import { compose } from '@grandvisionhq/utils'
import { InitialProps, Page, Props } from '@grandvisionhq/www-next/pages/book-appointment-v3'

import { withCms } from '../cms'
import BookAppointmentForm from '../components/forms/BookAppointmentForm'
import { getTimeFiltersConfig } from '../configuration'

import '@grandvisionhq/appointments/dist/index.css'

const timeFilterSettings = getTimeFiltersConfig()

const BookAppointmentV3: NextPage<Props, InitialProps> = ({ ...rest }) => {
  return (
    <Page
      {...rest}
      slots={{ confirmationPage }}
      bookAppointmentForm={BookAppointmentForm}
      timeFilterSettings={timeFilterSettings}
    />
  )
}

BookAppointmentV3.getInitialProps = Page.getInitialProps

export default compose<Props, Props>(withErrorPage(defaultErrorProps), withCms())(BookAppointmentV3)
